import { CustomDateAdapter } from './../../../adapters/customdate.adapter';
import { AuthenticationService } from './../../../services/authentication.service';
import { FileServer } from '../../../services/fileserver.service';
import { PasswordValidators } from 'ngx-validators';
import { User } from '../../../models/User';
import { DataService } from '../../../services/data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialog } from '../confirmdialog/confirm-dialog.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { environment } from 'src/environments/environment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'edit-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.

    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class EditUserDialog {
  public form: UntypedFormGroup;
  public formSMS: UntypedFormGroup;
  public mode = null;
  public item: User = null;
  public roles: any[] = [];
  protected server_errors = null;
  public imagePreview = null;
  protected avatar = null;
  protected tabs_fields = {
    'account': ['email', 'password', 'password_confirmation', 'roles'],
    'infos': ['firstname', 'lastname', 'alias', 'gender', 'mobile'],
    'misc': ['avatar']
  }
  protected save_clicked = false;
  public show_perms = false;
  protected me = false;
  public label_phone_verified = 'USER.PHONE_NOT_VERIFIED';
  public label_email_verified = 'USER.EMAIL_NOT_VERIFIED';
  public show_verif_code = false;
  public enable_verifications = true;
  public error_sms_code = false;
  public maxDate = new Date();

  constructor(
    public dataService: DataService,
    private translate: TranslateService,
    public fs: FileServer,
    public dialog: MatDialog,
    private snack: MatSnackBar,
    private auth : AuthenticationService,
    public dialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {


    this.item = data.item;
    this.me = !!data.me;

    this.enable_verifications = environment.auth.verifications == true;

    this.label_email_verified = this.translate.instant("USER.NOT_VERIFIED");
    this.label_phone_verified = this.translate.instant("USER.NOT_VERIFIED");

    if (this.item.exists()) {
      this.item['password'] = '--NOCHANGE--';
      this.item['password_confirmation'] = '--NOCHANGE--';

      if (this.item.email_verified) {
        this.label_email_verified = this.translate.instant("USER.VERIFIED");
      }
      if (this.item.phone_verified) {
        this.label_phone_verified = this.translate.instant("USER.VERIFIED");
      }
    }

    let roles_validator = [];
    if (data.show_perms) {

      this.show_perms = true;
      roles_validator = [Validators.required];

      this.dataService.getAsPromise('users/roles')
        .then(roles => {
          this.roles = roles;
        }).catch(err => {
          console.log('error getting roles')
        })
    }
    this.formSMS = new UntypedFormGroup({
      code: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(6), Validators.maxLength(6)]),
    });

    this.form = new UntypedFormGroup({
      alias: new UntypedFormControl('', [Validators.minLength(3), Validators.maxLength(64)]),
      firstname: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      lastname: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(128)]),
      password: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      password_confirmation: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      gender: new UntypedFormControl('', [Validators.required]),
      mobile: new UntypedFormControl('', [ Validators.pattern(/^(0033|33|\+33|40|\+40|0)(6|7|9)\d{8}$/)]),
      birthdate: new UntypedFormControl('', [ ]),
      notifications_email: new UntypedFormControl('', [ ]),
      notifications_fcm: new UntypedFormControl('', [ ]),
      avatar: new UntypedFormControl('', []),
      roles: new UntypedFormControl([], roles_validator)
    }, 
    { 
      updateOn: 'blur', 
      validators:[PasswordValidators.mismatchedPasswords('password', 'password_confirmation')]
    });


    this.form.patchValue(this.item);
    this.mode = this.item.exists() ? 'update' : 'new';



  }



  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.save_clicked = true;
    this.resetServerErrors();


    if (this.form.valid) {

      // check for phone change
      if (this.item.isVerified('phone') && this.form.controls['mobile'].value != this.item.mobile) {
        
        const dialogRef = this.dialog.open(ConfirmDialog, {
          width: '400px',
          data: {
            title: this.translate.instant('USER.CONFIRM_CHANGE_VERIFIED_PHONE_TITLE'),
            text: this.translate.instant('USER.CONFIRM_CHANGE_VERIFIED_PHONE_MSG')
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result != 'bt_yes') {
            // use old phone
            this.form.controls['mobile'].patchValue(this.item.mobile);
          } 
          this._saveData()

        });
      
        return;

      } else {

        this._saveData();

      }

    } else {
      console.log('form not valid')
    }
  }

  protected _saveData() {
    let data = this.form.value;
    
      if (this.mode == 'update') {
        let url = this.me ? `auth/me/${this.item.id}`: `users/${this.item.id}`;
        
        if (data['birthdate'] && data['birthdate']!='' && typeof(data['birthdate'])=='object') {
          data['birthdate'] = data['birthdate'].format('YYYY-MM-DD');
        }

        this.dataService.putAsPromise( url, data)
          .then(res => {

            if (this.avatar) {
              this.uploadAvatar()
                .then(res => {
                  this.dialogRef.close('bt_save');
                }).catch(error => {
                  console.log('ERROR', error)
                })
            } else {
              this.dialogRef.close('bt_save');
            }
          })
          .catch(error => {
            console.log('ERROR', error)
          })

      } else {
        this.dataService.postAsPromise(`users`, data)
          .then(res => {
            this.item.import(res.data.id);
            this.dialogRef.close('bt_save');
          })
          .catch(error => {
            console.log('ERROR', error);

            this.setServerErrors(error);
          })

      }
  }

  uploadAvatar() {
    return new Promise((resolve, reject) => {
      const formData = { 'avatar': this.imagePreview };
      let url = this.me ? `auth/me/${this.item.id}/avatar`: `users/${this.item.id}/avatar`;
      this.dataService
        .putAsPromise(url, formData)
        .then(res=>{
          resolve(res);
        })
        .catch(err=>{
          reject(err);
        })
    })
  }

  tabError(tab) {
    if (!this.tabs_fields[tab] || !this.save_clicked) {
      return 0;
    }
    let n = 0;
    this.tabs_fields[tab].forEach(f => {
      if (this.form.controls[f]) {
        if (!this.form.controls[f].valid) {
          n++;
        }
      } else {
        console.log('Error field missing:',f)
      }  
    });
    return n;
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }
  hasSMSError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.formSMS.hasError(errorName);
    }
    return this.formSMS.controls[controlName].hasError(errorName);
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.fields) {
      this.form.controls[i].setErrors({ serverError: true });
    }
  }
  resetServerErrors() {
    if (this.server_errors) {
      for (let i in this.server_errors.fields) {
       // this.form.controls[i].setErrors({ serverError: false });
        this.form.controls[i].markAsPristine();
        this.form.controls[i].markAsUntouched();
      }

      this.server_errors = null;
    }
  }

  getServerError(controlName: string) {
    if (this.server_errors && this.server_errors.fields && this.server_errors.fields[controlName]) {
      return this.translate.instant('STATIC.ERRORS.' + this.server_errors.fields[controlName].replace(/\./gi, ''));
    }
    return null;
  }

  onImageChange(event) {
    this.avatar = event.target.files[0];
    this.imagePreview = null;
    this.getImagePreview(this.avatar);
    this.form.markAsTouched();
  }

  getImagePreview(file: File) {
    const self = this;
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

      this.imagePreview = reader.result;



    };

  }

  sendVerification(mode) {
    if ( this.item.isVerified(mode)) {
      return;
    }

    if (mode=='phone') {
      // mobile is required
      this.form.controls['mobile'].setValidators([ Validators.required, Validators.pattern(/^(0033|33|\+33|40|\+40|0)(6|7|9)\d{8}$/)]);
      this.form.controls['mobile'].updateValueAndValidity();
      this.form.controls['mobile'].markAsTouched();
      
      this.save_clicked = true;

    }
    if (this.form.valid) {
      // only if form is valid
      let title = mode=='phone' ? this.translate.instant('USER.CONFIRM_VERIFICATION_PHONE_TITLE') :  this.translate.instant('USER.CONFIRM_VERIFICATION_EMAIL_TITLE');
      let msg = mode=='phone' ? this.translate.instant('USER.CONFIRM_VERIFICATION_PHONE_MSG') :  this.translate.instant('USER.CONFIRM_VERIFICATION_EMAIL_MSG');
      const dialogRef = this.dialog.open(ConfirmDialog, {
        width: '550px',
        data: {
          title: title,
          text: msg
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'bt_yes') {
          this.dataService.postAsPromise(`auth/sendverification/${this.item.id}`,{mode:mode})
            .then(res=>{
              this.snack.open( this.translate.instant('USER.EMAIL_VERIFICATION_SENT'),null, {duration:3000});
              this.show_verif_code = mode=="phone";
              this.formSMS.reset();
            })
            .catch(err=>{
              this.snack.open( this.translate.instant('STATIC.ERRORS.'+err),null, {duration:3000});
            })


        }

      });
    }


  }

  confirmSMS() {
    if (this.formSMS.valid) {
      this.formSMS.controls['code'].setErrors({ invalidcode: false });

      this.auth.checkVerification('mobile', this.formSMS.value['code'])
        .then(res=>{
          this.auth.loadCurrentUser();
          this.label_phone_verified = this.translate.instant("USER.VERIFIED");
          this.item.phone_verified = true;
          this.show_verif_code = false;
        })
        .catch(err=>{
          this.formSMS.controls['code'].setErrors({ invalidcode: true });
          this.formSMS.updateValueAndValidity();
        })
    }
  }

  keyPress( field, event) {

    let pattern = /[0-9]/;
    switch(field) {
      case 'code':
          pattern = /[0-9]/;
          break;
      case 'mobile':
          pattern = /[0-9\+]/;
          break;
      default:
        return true;    
          
    }
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {    
        // invalid character, prevent input
        event.preventDefault();
    }
  }

}
