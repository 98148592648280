import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthUser } from './../models/AuthUser';
import { User } from './../models/User';
import { DataService } from './data.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authenticated = false;
  protected api_root = environment.api.root;
  private currentAuthUserSubject: BehaviorSubject<AuthUser>;
  public currentAuthUser: Observable<AuthUser>;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private http: HttpClient;
  private _tokenInterval = null;
  private _tokenIntervalDuration = 60; // duration in seconds

  constructor(private handler: HttpBackend, private data: DataService) {
    this.http = new HttpClient(handler);

    this.loadCurrentAuthUser().then(res => {
      this.currentAuthUser.subscribe(user => {
        //console.log('------------- Auth Changed State --------------')
        if (user && user.exists()) {
          // autorefresh token
          this.autorefreshToken();

          //console.log('AuthUser=', user, this.currentUserSubject)
          if (!this.currentUserSubject.value) {
            // console.log('loading current user');
            this.loadCurrentUser();
          }
        } else {
          //console.log('--------- Auth state null ---------------')
          if (this.currentUserSubject.value) {
            this.currentUserSubject.next(null);
          }
        }
      });
    });
  }

  private loadCurrentAuthUser() {
    return new Promise((resolve, reject) => {
      let user = new AuthUser(JSON.parse(localStorage.getItem('currentUser')));

      this.currentAuthUserSubject = new BehaviorSubject<AuthUser>(user);
      this.currentAuthUser = this.currentAuthUserSubject.asObservable();

      this.currentUserSubject = new BehaviorSubject<User>(null);
      this.currentUser = this.currentUserSubject.asObservable();

      // revalidating token
      if (user.access_token) {
        this.refreshToken()
          .then(user => {
            resolve(user);
          })
          .catch(err => {
            //this.logout();
            resolve(null);
          });
      } else {
        resolve(user);
      }
    });
  }

  public loginWithEmail(credentials) {
    const url = this.api_root + '/auth/login';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then(res => {
          let user = new AuthUser(res);
          // check the whitelist
          if (environment.auth.roles && environment.auth.roles.whitelist) {
            let winter = _.intersection(environment.auth.roles.whitelist, user.roles);
            if (winter.length == 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (environment.auth.roles && environment.auth.roles.blacklist) {
            let binter = _.intersection(environment.auth.roles.blacklist, user.roles);
            if (binter.length > 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (user.exists() && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentAuthUserSubject.next(user);
            this.authenticated = true;
            resolve(true);
          } else {
            reject({ error: 'authentication_error' });
          }
        })
        .catch(err => {
          console.log('Error login', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public registerWithEmail(credentials) {
    const url = this.api_root + '/auth/register';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, credentials)
        .toPromise()
        .then(res => {
          let user = new AuthUser(res);

          // check the whitelist

          if (environment.auth.roles && environment.auth.roles.whitelist) {
            let winter = _.intersection(environment.auth.roles.whitelist, user.roles);
            if (winter.length == 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (environment.auth.roles && environment.auth.roles.blacklist) {
            let binter = _.intersection(environment.auth.roles.blacklist, user.roles);
            if (binter.length > 0) {
              return reject({ error: 'not_authorized' });
            }
          }
          if (user.exists() && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentAuthUserSubject.next(user);
            this.authenticated = true;
            resolve(true);
          } else {
            reject({ error: 'authentication_error' });
          }
        })
        .catch(err => {
          console.log('Error login', err);
          reject(this.parseErrors(err));
        });
    });
  }

  public checkVerification(mode, token) {
    const url = this.api_root + '/auth/verification';

    return new Promise((resolve, reject) => {
      this.http
        .post(url, { mode: mode, token: token })
        .toPromise()
        .then(res => {
          console.log('VERIF OK');
          resolve(res);
        })
        .catch(err => {
          console.log('Error login', err);
          reject(this.parseErrors(err));
        });
    });
  }

  public logout() {
    // remove user from local storage to log user out
    console.log('LOGOUT');
    localStorage.removeItem('currentUser');
    this.currentAuthUserSubject.next(null);
    if (this._tokenInterval) {
      clearInterval(this._tokenInterval);
    }
    window.location.href = '/';
  }

  public getCurrentAuthUser(): AuthUser {
    return this.currentAuthUserSubject.value;
  }

  public refreshToken(): Promise<AuthUser> {
    const url = this.api_root + '/auth/refresh';
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getCurrentAuthUser().access_token
    });

    return new Promise((resolve, reject) => {
      this.http
        .post(url, {}, { headers: header })
        .toPromise()
        .then(res => {
          let user = new AuthUser(res);
          if (user.exists() && user.access_token && user.roles && user.roles.length > 0) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentAuthUserSubject.next(user);
            this.authenticated = true;
            resolve(user);
          } else {
            reject('error_refresh_token');
          }
        })
        .catch(err => {
          console.log('Error refresh token', err);
          reject(err.error || 'Server error');
        });
    });
  }

  public getCurrentUser(): User {
    return this.currentUserSubject ? this.currentUserSubject.value : null;
  }

  public loadCurrentUser() {
    return new Promise((resolve, reject) => {
      this.data
        .getAsPromise('auth/me')
        .then(res => {
          if (res && res.data) {
            let user = new User(res.data);
            this.currentUserSubject.next(user);
            resolve(user);
          } else {
            reject('not_authenticated');
            this.logout();
          }
        })
        .catch(err => {
          console.log('Error ', err);
          reject(err);
        });
    });
  }

  autorefreshToken() {
    if (!this._tokenInterval) {
      this._tokenInterval = setInterval(() => {
        if (this.currentAuthUserSubject.value) {
          const token = this.currentAuthUserSubject.value.access_token;

          const helper = new JwtHelperService();
          const expirationDate = helper.getTokenExpirationDate(token);
          const isExpired = helper.isTokenExpired(token);

          if (isExpired) {
            this.logout();
            return false;
          }
          let delta = moment(expirationDate).diff(moment().utc(), 'seconds');
          if (delta <= this._tokenIntervalDuration) {
            this.refreshToken();
          }
        }
      }, this._tokenIntervalDuration * 1000);
    }
  }

  parseErrors(data) {
    let ret = data.error;
    ret.fields = {};
    if (data.error.errors && data.error.errors.length > 0) {
      data.error.errors.forEach(err => {
        ret.fields[err.field] = err.code;
      });
    }
    return ret;
  }
}
