// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p.content-text {
  font-family: Quicksand Light;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dialogs/confirmdialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ","sourcesContent":["p.content-text {\n    font-family: Quicksand Light;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
