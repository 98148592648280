import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dropdownmenu',
  templateUrl: './dropdownmenu.component.html',
  styleUrls: ['./dropdownmenu.component.scss']
})
export class DropDownMenuComponent implements OnInit {
  @Input() items : any[] = [];
  @Input() itemId : any = null;


  constructor(

  ) { }

  ngOnInit() {
  }

}
