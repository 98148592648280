import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import "moment/locale/fr";
import { AvailabilityData } from './../../forms/availability-field/availability-field.component';


@Component({
  selector: 'availability-grid',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],

})
export class AvailabilityComponent implements OnInit {
  public days_of_week;

  public availability_array = {
    'before_school': [0, 0, 0, 0, 0, 0, 0],
    'morning': [0, 0, 0, 0, 0, 0, 0],
    'noon': [0, 0, 0, 0, 0, 0, 0],
    'afternoon': [0, 0, 0, 0, 0, 0, 0],
    'after_school': [0, 0, 0, 0, 0, 0, 0],
    'evening': [0, 0, 0, 0, 0, 0, 0],
    'night': [0, 0, 0, 0, 0, 0, 0],
  }

  @Input() readonly:boolean = false;

  @Input()
  set data(av: AvailabilityData) {
    for (let i in av) {
      for (let p = 0; p < 7; p++) {
        let pow = Math.pow(2, p);
        if ((av[i] & pow) == pow) {
          this.availability_array[i][p] = 1;
        }
      }

    }
  }

  constructor(
    private translate: TranslateService
  ) {
    this.days_of_week = moment.weekdaysShort();
  }

  ngOnInit() {
  }

  onChange(event, index, item) {
    let check;
    if (event.checked) {
      check = 1;
    } else {
      check = 0;
    }

    this.availability_array[item][index] = check;
  }

  array(n: number): any[] {
    return Array(n);
  }

}
