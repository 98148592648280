// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
  opacity: 0;
  transition: opacity 200ms;
}

:host.floating span {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/forms/fileupload-field/fileupload-field.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,yBAAA;AACJ;;AACE;EACE,UAAA;AAEJ","sourcesContent":["span {\n    opacity: 0;\n    transition: opacity 200ms;\n  }\n  :host.floating span {\n    opacity: 1;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
