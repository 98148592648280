import { EditUserClientDialog } from "./../../components/dialogs/user-client-dialog/user-dialog.component";
import { AlphanumDirective } from "./../../directives/alphanum.directive";
import { FileUploadInput } from "./../../components/forms/fileupload-field/fileupload-field.component";
import { AvailabilityComponent } from "./../../components/misc/availability/availability.component";
import { AlertComponent } from "./../../components/misc/alert/alert.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "ngx-moment";
import { ValidatorsModule } from "ngx-validators";
import { ConfirmDialog } from "src/app/components/dialogs/confirmdialog/confirm-dialog.component";
import { AutocompleteChipsInput } from "src/app/components/forms/autocomplete-chips/autocomplete-chips.component";
import { HasPermsDirective } from "src/app/directives/hasperms.directive";
import { SafePipe } from "src/app/pipes/safe.pipe";
import { EditUserDialog } from "../../components/dialogs/userdialog/user-dialog.component";
import { AvatarComponent } from "../../components/misc/avatar/avatar.component";
import { AvailabilityFieldInput } from "../../components/forms/availability-field/availability-field.component";
import { DropDownMenuComponent } from "./../../components/menus/dropdownmenu/dropdownmenu.component";
import { HasNotPermsDirective } from "./../../directives/hasnotperms.directive";
import { SecurePipe } from "./../../pipes/secure.pipe";
import { AvailabilityDialog } from "src/app/components/dialogs/availabilitydialog/availability-dialog.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSliderModule } from "@angular/material/slider";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  declarations: [
    ConfirmDialog,
    EditUserDialog,
    EditUserClientDialog,
    AvailabilityDialog,
    DropDownMenuComponent,
    ConfirmDialog,
    HasPermsDirective,
    HasNotPermsDirective,
    AlphanumDirective,
    AvatarComponent,
    SecurePipe,
    SafePipe,
    AutocompleteChipsInput,
    AvailabilityDialog,
    AvailabilityFieldInput,
    AlertComponent,
    AvailabilityComponent,
    FileUploadInput,
  ],
  // entryComponents: [
  //   ConfirmDialog,
  //   EditUserDialog,
  //   EditUserClientDialog,
  //   AvailabilityDialog,
  // ],
  imports: [
    LayoutModule,
    CommonModule,
    ValidatorsModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatBadgeModule,
    FormsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    TranslateModule,
    MatDialogModule,
    MatAutocompleteModule,
    DragDropModule,
    MomentModule,
    MatSnackBarModule,
    MatCheckboxModule,
    FlexLayoutModule,
  ],
  exports: [
    CommonModule,
    LayoutModule,
    ValidatorsModule,
    MatButtonModule,
    MatBadgeModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatSelectModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatExpansionModule,
    FormsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    DropDownMenuComponent,
    ConfirmDialog,
    EditUserDialog,
    EditUserClientDialog,
    MatDialogModule,
    TranslateModule,
    HasPermsDirective,
    HasNotPermsDirective,
    AlphanumDirective,
    MatAutocompleteModule,
    DragDropModule,
    MomentModule,
    AvatarComponent,
    SecurePipe,
    SafePipe,
    MatSnackBarModule,
    AutocompleteChipsInput,
    MatCheckboxModule,
    AvailabilityDialog,
    AvailabilityFieldInput,
    AlertComponent,
    AvailabilityComponent,
    FileUploadInput,
    FlexLayoutModule,
  ],
})
export class SharedModule {}
