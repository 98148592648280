import { EditUserClientDialog } from './../components/dialogs/user-client-dialog/user-dialog.component';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: "root"
})
export class NavigationService {
  public pageTitle = '';

  protected main_menu_auth = [

    { type: "item", label: "STATIC.NAV.HOME", path: "/dashboard", icon: "dashboard", xroles:['super-admin'] },
    // for admin
    { type: "item", label: "STATIC.NAV.CLIENTS", path: "/admin/clients", icon: "business", roles:['super-admin'] },
    { type: "item", label: "STATIC.NAV.USERS", path: "/admin/users", icon: "people", roles:['super-admin','client-admin'] },
    //{ type: "item", label: "STATIC.NAV.PARAMETERS", path: "/admin/parameters", icon: "settings", roles:['super-admin'] },
    //{ type: "item", label: "STATIC.NAV.LISTS", path: "/admin/lists", icon: "lists", roles:['super-admin'] },
    { type: "separator" }
  ];
  protected main_menu = [];


  protected profile_menu_auth = [
    { type: "item", label: "STATIC.NAV.ACCOUNT", icon: "account_circle", click:()=>{ this.showUserDialog()} },
    ];
  protected profile_menu = [
  //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {
    const self = this;

  }

  getMenu(menu) {
    let user = this.auth.getCurrentAuthUser();

    switch (menu) {
      case 'main':
        return user.exists() ? this.main_menu_auth : this.main_menu;
        break
      case 'profile':
        return user.exists() ? this.profile_menu_auth : this.profile_menu;
        break  

    }
  }

  logout() {
    this.auth.logout();
  }

  setTitle(title, subtitle = '') {
    if (title) {
      this.pageTitle = this.translate.instant(title);
      if (subtitle) {
        this.pageTitle += ' : ' + subtitle;
      }
    } else {
      this.pageTitle='';
    }
  }

  getPageTitle() {
    return this.pageTitle;
  }

  showUserDialog() {
    let title = this.translate.instant('USER.MY_PROFILE');
    let user = this.auth.getCurrentUser();
    const dialogRef = this.dialog.open(EditUserClientDialog, {
      width: '550px',
      data: {
        title: title,
        item: user,
        me :true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.auth.loadCurrentUser();
    });
  }
}
