import { AvailabilityDialog } from './../../dialogs/availabilitydialog/availability-dialog.component';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl } from '@angular/material/form-field';


export interface AvailabilityData {
    before_school: number;
    morning: number;
    noon:  number;
    afternoon: number;
    after_school: number;
    evening: number;
    night: number;
};

/**
 * @title Availability field
 */
@Component({
    selector: 'availability-field',
    templateUrl: './availability-field.component.html',
    styleUrls: ['./availability-field.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: AvailabilityFieldInput },
    ],

})
export class AvailabilityFieldInput
    implements
    MatFormFieldControl<AvailabilityData>,
    ControlValueAccessor,
    OnInit,
    DoCheck {

    fieldCtrl = new UntypedFormControl();

    stateChanges = new Subject<void>();
    static nextId = 0;
    private _placeholder: string;
    focused = false;
    errorState = true;
    controlType = 'address-field';

    chips : string[] = [];
    availability: AvailabilityData = null;

    @Input()
    color: string = null;
    @Input()
    readonly: boolean = false;

    @Input()
    dlgTitle: string = null;
    @Input()
    dlgCancelButton: string = null;
    @Input()
    dlgSaveButton: string = null;
    @Input()
    editable: boolean = false;

    @Input()
    visible: boolean = true;
    @Input()
    addOnBlur: boolean = true;
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get value(): AvailabilityData | null {
        return this.availability && !this.isAvailabilityEmpty() ? this.availability : null;
    }
    set value(val: AvailabilityData | null) {
        this.availability = val;
        this.chips = this.availabilityToArray();
        this.stateChanges.next();
        this.onChange(val);
        this.onTouch();

    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {

        this._required = coerceBooleanProperty(req);
        this._required = !!req;
        this.stateChanges.next();
    }
    private _required = false;

    get empty() {
        return !this.availability || this.isAvailabilityEmpty();
    }
    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled = !!value;
        this.stateChanges.next();
    }
    private _disabled = false;

    @HostBinding() id = `availability-field'-${AvailabilityFieldInput.nextId++}`;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        fb: UntypedFormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        private translate: TranslateService,
        private dialog: MatDialog
    ) {

        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });

        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }


    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }


    onContainerClick(event: MouseEvent) {
        this.chips = this.availabilityToArray();
        if (!this._disabled && !this.readonly) {
            this.openDialog();
        }
    }

    onChange = (value: AvailabilityData) => { };

    onTouch = () => { };

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(value: AvailabilityData): void {
        this.availability = value;
        this.chips = this.availabilityToArray();
    }

    availabilityToArray() {
        let ret = [];
        for(let i in this.availability) {
            if (this.availability[i]>0) {
                ret.push(i)
            }
        }
        return ret;
    }

    isAvailabilityEmpty() {
        let ret = 0;
        for(let i in this.availability) {
            ret+= this.availability[i];
        }
        return ret==0;
    }



    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (value: AvailabilityData) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {

    }

    ngOnInit() { }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    updateValue() {
        this.chips = this.availabilityToArray();
        this.writeValue(this.availability);
        this.onChange(this.availability);
        this.onTouch();
    }

    remove(item: string): void {
        this.availability[item] = 0;
        this.updateValue();
    }


    openDialog(): void {
        this.onTouch();
        let data = {
            title: this.dlgTitle || this.translate.instant('AVAILABILITY.DIALOG_TITLE'),
            availability: this.availability,
            cancelButton: this.dlgCancelButton,
            saveButton: this.dlgSaveButton
        };


        const dialogRef = this.dialog.open(AvailabilityDialog, {
            width: '550px',
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'bt_save') {
                this.availability = data.availability;
                this.updateValue();
            }
        });
    }

}
