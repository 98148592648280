// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-icon {
  padding: 0 14px;
}

.example-spacer {
  flex: 1 1 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/layouts/default-layout/default-layout.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEE;EACE,cAAA;AACJ","sourcesContent":[".example-icon {\n    padding: 0 14px;\n  }\n  \n  .example-spacer {\n    flex: 1 1 auto;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
