import { DataService } from './../../../services/data.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Input, HostBinding, Optional, Self, forwardRef, OnInit, DoCheck } from '@angular/core';
import { UntypedFormControl, NgControl, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ValueList, ValueListItem } from 'src/app/models/ValueList';
import * as _ from 'lodash';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl } from '@angular/material/form-field';


/**
 * @title Chips Autocomplete
 */
@Component({
    selector: 'autocomplete-chips',
    templateUrl: './autocomplete-chips.component.html',
    styleUrls: ['./autocomplete-chips.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: AutocompleteChipsInput },
    ],

})
export class AutocompleteChipsInput
    implements
    MatFormFieldControl<any[]>,
    ControlValueAccessor,
    OnInit,
    DoCheck {


    separatorKeysCodes: number[] = [ENTER, COMMA];
    stateChanges = new Subject<void>();
    static nextId = 0;
    private _placeholder: string;
    focused = false;
    errorState = true;
    controlType = 'autocomplete-chips';

    fieldCtrl = new UntypedFormControl();
    filteredList: Observable<ValueListItem[]>;
    selectedItems: ValueListItem[] = [];

    _value: any[] = [];
    list: ValueList = null;
    _populated = false;
        
    @Input()
    multiple: boolean = true;
    @Input()
    color: string = null;
    @Input()
    editable: boolean = false;
    @Input()
    selectable: boolean = true;
    @Input()
    removable: boolean = true;
    @Input()
    readonly: boolean = false;
    @Input()
    visible: boolean = true;
    @Input()
    addOnBlur: boolean = true;
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get value(): any[] | null {
        return this._value;
    }
    set value(val: any[] | null) {

        this.initValue(val);
        this.stateChanges.next();
        this.onChange(val);
        this.onTouch();

    }


    @Input()
    set listname(listname: string) {
        // load list from dataService
        this.data.getListData(listname)
            .then(list => {
                this.list = list;
                
                this.populateSelected();
            })
            .catch(err => {
                this.list = new ValueList();
            })
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    get empty() {
        return !this._value || this._value.length == 0;
    }
    @Input()

    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.fieldCtrl.disable() : this.fieldCtrl.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @HostBinding() id = `autocomplete-chips-${AutocompleteChipsInput.nextId++}`;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }



    @ViewChild('chipInput', { static: false }) chipInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('auto', { static: false, read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;
    constructor(
        private data: DataService,
        @Optional() @Self() public ngControl: NgControl,
        fb: UntypedFormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>
    ) {


        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });

        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }

        this.filteredList = this.fieldCtrl.valueChanges.pipe(
            startWith(null),
            map((item: ValueListItem | null) => {
                if (!this.list) {
                    return [];
                }
                return this._filter(item)
     
            }
            ));
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }

    onContainerClick(event: MouseEvent) {
        this.fieldCtrl.setValue('');

        if (!this.readonly && (event.target as Element).tagName.toLowerCase() != 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        } 
    }

    onChange = (value: any[]) => { };

    onTouch = () => {};

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(value: any[]): void {
        this.initValue(value);
        
        if (this.chipInput) {
            this.chipInput.nativeElement.blur()
        }
    }

    initValue(value: any[]): void {
        if (!value) {
            value = [];
        } else if (typeof value == 'string') {
            value = [value];
        } else if (typeof(value.length)=='undefined') {
            value = [value];
        }
        this._value = value;
        this.populateSelected();
    }

    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (value: any[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {

    }

    ngOnInit() {}

    ngDoCheck(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    updateValue() {
        this.fieldCtrl.setValue('');
        this.writeValue(_.map(this.selectedItems, (o) => o.value));
        this.onChange(this._value);
        this.onTouch();
    }

    private populateSelected() {


        if (this.list && this._value) {
            this._populated = true;
            this.selectedItems = [];
            this._value.forEach(v => {
                let o = _.find(this.list.items, { value: v });
                if (o) {
                    this.selectedItems.push(o);
                } else {
                    let n: ValueListItem = {
                        id: null,
                        value_list_id: this.list.id,
                        label: v,
                        value: v,
                        order: 0,
                        added: ''
                    };

                    this.selectedItems.push(n);
                }
            })
        }

    }

    add(event: MatChipInputEvent): void {
        // Add only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;
            // Add our fruit
            if (this.editable && (value || '').trim()) {
                let n: ValueListItem = {
                    id: null,
                    value_list_id: this.list.id,
                    label: value.trim(),
                    value: value.trim(),
                    order: 0,
                    added: ''
                };
                if (this.multiple) {
                    this.selectedItems.push(n);
                } else {
                    this.selectedItems = [n];
                }
            }

            // Reset the input value
            if (input) {
                input.value = '';
            }
            this.updateValue();
        }
    }

    remove(item: ValueListItem): void {

        _.remove(this.selectedItems, (o) => {
            return o.value == item.value
        });
        this.updateValue();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        // check if value not yet there
        let o = _.find(this.selectedItems, { value: event.option.value.value });
        if (!o) {
            if (this.multiple) {
                this.selectedItems.push(event.option.value);
            } else {
                this.selectedItems = [event.option.value]; 
            }
        }
        this.chipInput.nativeElement.value = '';
        this.fieldCtrl.setValue(null);
        this.updateValue();

    }

    private _filter(label: any): ValueListItem[] {
        let ret = [];
        if (!label || (label && typeof label != 'string') ) {
            //return this.list.items;
            ret =  _.filter(this.list.items,  (o)=> {
                return !this._value || this._value.indexOf(o.value)<0;
            });
        } else {
            const filterValue = label.toLowerCase();

            ret =  _.filter(this.list.items, (o)=> {
                return this._value.indexOf(o.value)<0 && o.label.toLowerCase().indexOf(label) === 0;
            });
        }
        return ret;
    }
}
