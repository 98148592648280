// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
:host .xs {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
:host .sm {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
:host .md {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
:host .lg {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}
:host .xl {
  width: 400px;
  height: 400px;
  border-radius: 50%;
}
:host .identity {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/misc/avatar/avatar.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAAR;AAGI;EACI,YAAA;EACA,aAAA;EACA,kBAAA;AADR;AAII;EACI,YAAA;EACA,aAAA;EACA,kBAAA;AAFR;AAKI;EACI,YAAA;EACA,aAAA;EACA,kBAAA;AAHR;AAMI;EACI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAJR","sourcesContent":[":host{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    \n    .xs{\n        width:30px;\n        height:30px;\n        border-radius:50%;\n    }\n      \n    .sm{\n        width:50px;\n        height:50px;\n        border-radius:50%;\n    }\n      \n    .md{\n        width:120px;\n        height:120px;\n        border-radius:50%;\n    }\n      \n    .lg{\n        width:180px;\n        height:180px;\n        border-radius:50%;\n    }\n      \n    .xl{\n        width:400px;\n        height:400px;\n        border-radius:50%;\n    }\n\n    .identity{\n        color:white;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
